import React, { useRef, useState, useEffect } from 'react';

// JSON:ssa \n toimii rivinvaihtona.
function formatContent(content) {
  return content.split('\n').map((item, index) => (
    <React.Fragment key={index}>
      {item}
      <br />
    </React.Fragment>
  ));
}

const NewsPost = ({ post, isExpanded, onToggleExpand, postsPerPage }) => {
  const postRef = useRef(null);
  const [originalHeight, setOriginalHeight] = useState(0); // Postauksen korkeus

  // Korkeuden päivitys, jos sitä ei ole vielä määritelty
  useEffect(() => {
    if (postRef.current && (!originalHeight || !isExpanded)) {
      setOriginalHeight(postRef.current.offsetHeight);
    }
  }, [isExpanded, originalHeight]);

  // Otetaan postausten välien koko huomioon laajennuksessa (gap-2 = 8px)
  const totalGapSize = (postsPerPage - 1) * 8;
  const expandedStyle = isExpanded ? { minHeight: `calc(${originalHeight}px * ${postsPerPage} + ${totalGapSize}px)` } : {};

  return (
    <div 
      ref={postRef} 
      style={expandedStyle} 
      className={`border border-white bg-black bg-opacity-25 p-2 w-auto flex flex-col origin-top transform transition-transform duration-300 ease-out ${isExpanded ? 'h-auto hover:border-black hover:border-opacity-25 hover:shadow-subtle-glow scale-100' : 'hover:border-highlight hover:shadow-subtle-glow scale-95'}`}
      onClick={onToggleExpand}
    >
      <div className="flex-grow">
        <h3 className={`text-left ${isExpanded ? '' : 'line-clamp-1'} text-lg font-bold`}>{post.title}</h3>
        <div className="w-full max-w-xs">
          <hr className="border-t border-white" />
        </div>
        <p className={`text-left ${isExpanded ? '' : 'line-clamp-2'} overflow-hidden font-medium pt-1`}>{formatContent(post.content)}</p>
      </div>
      <div className="flex justify-between items-end">
        <p className="text-left text-xs">{post.date}</p>
      </div>
    </div>
  );
};

export default NewsPost;
