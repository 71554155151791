import axios from 'axios';

let API_BASE_URL = process.env.REACT_APP_DOMAIN_NAME;
if (!API_BASE_URL) {
  API_BASE_URL = 'https://localhost:3001';
}

// Dynaaminen sendEmail service kaikille endpointeille
const sendEmail = async (formData, endpoint) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/${endpoint}`, formData, {
      timeout: 5000,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

export { sendEmail };