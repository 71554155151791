import { useState, useMemo } from 'react';
import CommonButton from '../components/CommonButton';

// Passataan arrayn pituus, montako itemiä per sivu ja halutessa montako sivunumeroa näkyvissä
const usePagination = (totalItems, itemsPerPage, maxPagesToShow = 3) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(() => Math.ceil(totalItems / itemsPerPage), [totalItems, itemsPerPage]);

  const currentItemsSlice = useMemo(() => {
    const end = currentPage * itemsPerPage;
    const start = end - itemsPerPage;
    return { start, end };
  }, [currentPage, itemsPerPage]);

  const goToNextPage = () => setCurrentPage(current => Math.min(current + 1, totalPages));
  const goToPreviousPage = () => setCurrentPage(current => Math.max(current - 1, 1));

  // Sivunumeroiden generointi
  const pageNumbers = useMemo(() => {
    let pages = [];
    if (totalPages <= maxPagesToShow) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      let startPage = Math.max(currentPage - halfMaxPagesToShow, 1);
      let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

      if (currentPage + halfMaxPagesToShow > totalPages) {
        startPage = totalPages - maxPagesToShow + 1;
      }
      pages = Array.from({ length: (endPage - startPage) + 1 }, (_, i) => startPage + i);
    }
    return pages;
  }, [currentPage, maxPagesToShow, totalPages]);

  // Nuolinavigaationappulat
  const ArrowLeft = () => (
    totalItems > itemsPerPage ? (
      <CommonButton
        className={"m-2 p-2 flex justify-center items-center border-white"}
        onClick={goToPreviousPage}
        disabled={currentPage === 1}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 19L8 12L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </CommonButton>
    ) : (
      <div></div>
    )
  );

  const ArrowRight = () => (
    totalItems > itemsPerPage ? (
      <CommonButton
        className={"m-2 p-2 flex justify-center items-center border-white"}
        onClick={goToNextPage}
        disabled={currentPage === totalPages}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 5L16 12L9 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </CommonButton>
    ) : (
      <div></div>
    )
  );

  // Sivunumerokomponentti
  const PageNumbers = ({ onPageChange }) => (
    totalItems > itemsPerPage ? (
      <div>
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={`p-2 font-medium ${number === currentPage ? 'border-b-2 border-white' : ''}`}
          >
            {number}
          </button>
        ))}
      </div>
    ) : (
      <div></div>
    )
  );

  return { currentPage, totalPages, currentItemsSlice, goToNextPage, goToPreviousPage, setCurrentPage, ArrowLeft, ArrowRight, PageNumbers };
};

export default usePagination;