import React from 'react';
import { Link } from 'react-router-dom';
import { baseButtonStyles, mobileNavigationStyles, specialStyles } from '../../styles/navigationStyles';

// Default navigaatiopainike
const NavButton = ({ children, special, mobile, to, className, setIsMobileMenuOpen = () => {} }) => {
  
  const closeMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const buttonClass = `${baseButtonStyles} ${special ? specialStyles : ""} ${mobile ? mobileNavigationStyles : ""} ${className}`;

  return (
    <Link to={to} className={buttonClass} onClick={closeMenu}>
      {children}
    </Link>
  );
};

export default NavButton;
