import { useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Captcha = ({ action, setToken }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
  
    useEffect(() => {
      const doExecuteRecaptcha = async () => {
        if (!executeRecaptcha) {
          console.log('Execute reCAPTCHA not available.');
          return;
        }
  
        const token = await executeRecaptcha(action);
        setToken(token);
      };
  
      doExecuteRecaptcha();
    }, [executeRecaptcha, action, setToken]);
  
    // Koska reCAPTCHA v3 suoritetaan taustalla, komponentti ei renderöi mitään visuaalista
    return null;
  };
  
  export default Captcha;
