import React from 'react';

const ResponsiveImage = ({ imagePath }) => {
  return (
      <div className="aspect-w-16 aspect-h-9">
        <img src={imagePath} alt="Logo" className="object-contain w-full h-full" />
      </div>
  );
};

export default ResponsiveImage;
