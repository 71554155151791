import React from 'react';
import { Field, useFormikContext } from 'formik';
import CommonButton from "./CommonButton";
import { useTranslation } from "react-i18next";
import { handleFileInputChange } from '../utils/fileHandler';
import Select from 'react-select';

// custom Field komponentti, jonka avulla käsitellään file tyyppisten asioiden lataaminen
const CustomField = ({ field, onFileSelected, initialValues, ...props }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  // Jos kenttä on file tyyppinen
  if (field.type === 'file') {
    const handleChange = (event) => {
      const fileName = handleFileInputChange(event, setFieldValue, field.name);
      if (onFileSelected && fileName) {
        onFileSelected(field.name, fileName.name, values);
      }
    };

    return (
      <div className="flex flex-row items-center">
        <CommonButton
          className="px-1 py-1 min-w-fit border-white hover:bg-highlight focus:outline-none text-sm truncate"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            document.getElementById(field.name).click();
          }}
        >
          {t("general.application_form.select_file")}
        </CommonButton>

        <input
          id={field.name}
          name={field.name}
          type="file"
          onChange={handleChange}
          className="hidden"
          accept={field.accept}
        />
      </div>
    );
  } else if (field.type === 'select') {
    const options = field.options.map(option => ({
      value: option.value,
      label: t(option.label)
    }));
    const initialValue = options.find(option => option.value === initialValues?.subject) || options.find(option => option.value === 'other');

    // Custom select using react-select
    return (
      <Select
        id={field.name}
        name={field.name}
        value={initialValue}
        options={options}
        className="text-sm"
        classNamePrefix="select"
        onChange={option => setFieldValue(field.name, option.value)}
        styles={customStyles}
        {...props}
      />

    );
  }

  // Vakio kenttä
  return (
    <Field
      id={field.name}
      name={field.name}
      className="px-1 py-1 border border-1 font-medium border-white focus:outline-none focus:border-transparent bg-white bg-opacity-25"
      as={field.type === 'textarea' ? 'textarea' : 'input'}
      {...props}
    />
  );
};

export default CustomField;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: 'rgba(255, 255, 255, 0.25)',
    borderColor: state.isFocused ? 'transparent' : 'white',
    borderWidth: 1,
    boxShadow: state.isFocused ? 'none' : 'none',  // Ensure no box-shadow on focus
    cursor: 'default',
    outline: 'none', // Remove blue outline on focus or hover
    padding: '0rem 0rem',
    fontSize: '0.96rem',
    color: 'white',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '500',
    borderRadius: 0,
    minHeight: '38px',
    '&:hover': {
      borderColor: 'none'  // Ensure no change in border color on hover
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: '500',
    padding: '0px 2px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
    fontWeight: '500',
    boxShadow: 'none',
    textShadow: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white',
    fontWeight: '500',
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    borderRadius: 0,
    fontWeight: '500',
    opacity: '0.95',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    fontWeight: '500',
    textShadow: 'none',
    borderRadius: 0,
    padding: '0.25rem 0.4rem',
    ':hover': {
      backgroundColor: 'rgba(200, 200, 200, 0.3)',
      color: 'black',
    },
  }),
  input: (provided) => ({
    ...provided,
    fontWeight: '500',
    color: 'black',
    caretColor: 'transparent'
  }),
};



