const Yup = require('yup');

const subjectOptions = ['ict', 'design', 'sale', 'request', 'other'];

const contactValidationSchema = Yup.object({
    firstName: Yup.string().required('Required').min(1, 'Too short').max(30, 'Too long'),
    lastName: Yup.string().required('Required').min(1, 'Too short').max(30, 'Too long'),
    email: Yup.string().required('Required').email('Invalid email address'),
    subject: Yup.string().required('Required').oneOf(subjectOptions, 'Invalid subject'),
    message: Yup.string().required('Required').min(10, 'Message too short').max(500, 'Message too long'),
    privacyPolicyAccepted: Yup.boolean().oneOf([true], 'Required'),
});

const ictApplicationValidationSchema = Yup.object({
    firstName: Yup.string().required('Required').min(1, 'Too short').max(30, 'Too long'),
    lastName: Yup.string().required('Required').min(1, 'Too short').max(50, 'Too long'),
    phone: Yup.string().required('Required')
        .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Invalid phone number'),
    email: Yup.string().required('Required').email('Invalid email address'),
    degree: Yup.string().required('Required').min(2, 'Too short').max(50, 'Too long'),
    message: Yup.string().required('Required').min(10, 'Message too short').max(500, 'Message too long'),
    privacyPolicyAccepted: Yup.boolean().oneOf([true], 'Required'),
});

const electricalApplicationValidationSchema = Yup.object({
    firstName: Yup.string().required('Required').min(1, 'Too short').max(30, 'Too long'),
    lastName: Yup.string().required('Required').min(1, 'Too short').max(50, 'Too long'),
    phone: Yup.string().required('Required')
        .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Invalid phone number'),
    email: Yup.string().required('Required').email('Invalid email address'),
    degree: Yup.string().required('Required').min(2, 'Too short').max(50, 'Too long'),
    message: Yup.string().required('Required').min(10, 'Message too short').max(500, 'Message too long'),
    privacyPolicyAccepted: Yup.boolean().oneOf([true], 'Required'),
});

const automationApplicationValidationSchema = Yup.object({
    firstName: Yup.string().required('Required').min(1, 'Too short').max(30, 'Too long'),
    lastName: Yup.string().required('Required').min(1, 'Too short').max(50, 'Too long'),
    phone: Yup.string().required('Required')
        .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Invalid phone number'),
    email: Yup.string().required('Required').email('Invalid email address'),
    degree: Yup.string().required('Required').min(2, 'Too short').max(50, 'Too long'),
    message: Yup.string().required('Required').min(10, 'Message too short').max(500, 'Message too long'),
    privacyPolicyAccepted: Yup.boolean().oneOf([true], 'Required'),
});

const technicianApplicationValidationSchema = Yup.object({
    firstName: Yup.string().required('Required').min(1, 'Too short').max(30, 'Too long'),
    lastName: Yup.string().required('Required').min(1, 'Too short').max(50, 'Too long'),
    phone: Yup.string().required('Required')
        .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Invalid phone number'),
    email: Yup.string().required('Required').email('Invalid email address'),
    degree: Yup.string().required('Required').min(2, 'Too short').max(50, 'Too long'),
    message: Yup.string().required('Required').min(10, 'Message too short').max(500, 'Message too long'),
    privacyPolicyAccepted: Yup.boolean().oneOf([true], 'Required'),
});

module.exports = {
    contactValidationSchema,
    ictApplicationValidationSchema,
    electricalApplicationValidationSchema,
    automationApplicationValidationSchema,
    technicianApplicationValidationSchema
};