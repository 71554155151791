import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Content from '../containers/Content';
import ContactCard from '../components/contact/ContactCard';
import Title from '../components/Title';
import contacts from '../assets/contacts/contacts.json';

import useWindowSize from '../hooks/useWindowSize';
import usePagination from '../hooks/usePagination'; 

const ArrowLeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className="w-6 h-6 text-white"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 19l-7-7m0 0l7-7m-7 7h18"
    />
  </svg>
);

const AllContacts = () => {
  const { t } = useTranslation();
  const width = useWindowSize();
  const [contactsPerPage, setContactsPerPage] = useState(getContactsPerPage(width));  // Pitää hakutulos korkeutta, jos muuttaa
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredContacts, setFilteredContacts] = useState(contacts);

  const { currentItemsSlice, setCurrentPage, ArrowLeft, ArrowRight, PageNumbers } = usePagination(filteredContacts.length, contactsPerPage);

  // Asetetaan korttien määrä ikkunassa sen koon mukaan
  // Lisäksi vaihdetaan sivua tarpeen mukaan, ettei voi jäädä tyhjälle sivulle
  useEffect(() => {
    const newContactsPerPage = getContactsPerPage(width);
    setContactsPerPage(newContactsPerPage);
  }, [width]);

  function getContactsPerPage(width) {
    if (width > 1024) return 16;
    else if (width > 768) return 12;
    else if (width > 640) return 8;
    return 4;
  }

  const getLocalizedTitle = (titleKey) => {
    return t(titleKey);
  };

  // Päivitetään lista aina kun haku muuttuu
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filteredData = contacts.filter(contact =>
      contact.firstName.toLowerCase().includes(lowercasedQuery) ||
      contact.lastName.toLowerCase().includes(lowercasedQuery) ||
      contact.phoneNumber.toLowerCase().includes(lowercasedQuery) ||
      contact.tags.some(tag => tag.toLowerCase().includes(lowercasedQuery)) ||
      getLocalizedTitle(`company_page.departments.${contact.title}`).toLowerCase().includes(lowercasedQuery)
    );
    setFilteredContacts(filteredData);
    setCurrentPage(1);
    // eslint-disable-next-line
  }, [searchQuery, setCurrentPage]);
  
  const currentContacts = filteredContacts.slice(currentItemsSlice.start, currentItemsSlice.end);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Function to handle manual page change
  };

  return (
    <Content className="px-8 mx-auto">
      <div className="w-full flex flex-col my-auto gap-8">

        {/* Ylärivi, Otsikko & Hakulaatikko */}
        <div className="flex flex-row justify-between items-center w-full">
          <div className="hidden lg:flex size-1/3">
          <Link to="/contactinfo" className="mr-4">
              <ArrowLeftIcon />
            </Link>
          </div>
          
          {/* Title with Left Arrow */}
          <div className="flex lg:size-1/3 justify-start md:justify-center h-full items-center">
            <Title title={t('contactinfo_page.header')} className=""></Title>
          </div>

          {/* Hakulaatikko */}
          <input
            type="text"
            className="flex size-1/3 h-8 border border-white bg-[#737373] text-lg px-2 text-start bg-opacity-25 focus:outline-none focus:shadow-sm hover:bg-opacity-50"
            placeholder={t('contactinfo_page.search_placeholder')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Hakutulokset (Kortit) */}
        <div className="min-h-[450px] lg:w-[1180px] flex flex-col justify-between">
          <div>
            <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {currentContacts.map(contact => (
                <ContactCard key={contact.id} contact={contact}/>
              ))}
            </div>
          </div>

          {/* Sivunavigaatio */}
          <div className="mt-4 flex justify-center items-center">
            <ArrowLeft />
            <PageNumbers onPageChange={handlePageChange} />
            <ArrowRight />
          </div>

        </div>
      </div>
    </Content>
  );
};

export default AllContacts;
