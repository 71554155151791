const imageUrls = {
  logo: require("../assets/images/PasramOy-Logo.png"),
  mainBackground: require("../assets/background/toimisto_1.jpg"),
  siemens: require("../assets/images/siemens-logo-2.png"),
  smc: require("../assets/images/smc-logo.png"),
  kaappi: require("../assets/images/sähkökeskus-1024x733.jpg"),
  installation: require("../assets/images/PasramInstallation.png"),
};

export default imageUrls;
