import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';

const PageLayout = ({ children }) => {

  return (
    <div className="flex flex-col min-h-screen">
      <Navigation />
      <main className="flex-grow flex flex-col">
        {children}
      </main>
      <Footer />
    </div>
    
  );
};

export default PageLayout;