import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BackgroundImageProvider } from './contexts/BackgroundContext';
import PageLayout from './containers/PageLayout';
import LanguageSwitcher from './components/LanguageSwitcher';
import RoutesConfig from './RoutesConfig';
import ReactGA from "react-ga4";
import CookieConsentBanner from './components/CookieConsentBanner';
import './App.css';
import i18n from './i18n';

function App() {

  // Varmistetaan kielen lataus alussa
  useEffect(() => {
    const currentLng = 'fi'; // Default suomi
    i18n.changeLanguage(currentLng);
  }, []);

  const handleConsentGiven = useCallback(() => {
    const gaConsent = localStorage.getItem('ga-consent');
    if (gaConsent === 'true') {
      ReactGA.send({ hitType: 'pageview' });
    }
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lc8JQwqAAAAANwtPAJNyxbuLVpMQ6-QGohHxLch">
      <Router>
        <CookieConsentBanner onConsentGiven={handleConsentGiven} />
        <BackgroundImageProvider>
          <LanguageSwitcher />
          <PageLayout>
            <RoutesConfig /> 
          </PageLayout>
        </BackgroundImageProvider>
      </Router>
    </GoogleReCaptchaProvider>
  );
}

export default App;