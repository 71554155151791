import React from 'react';
import { getImagePath } from "../../utils/getImagePath";

const ManagerCard = ({ contact }) => {
  return (
    <div className="flex flex-col items-center overflow-hidden border border-white sm:min-w-[220px] max-w-[300px] shadow-lg p-2 bg-black bg-opacity-25">
      <img 
        className="object-cover sm:h-48 sm:w-48 rounded-[50%]"
        src={getImagePath(contact.imageFileName)}
        alt="headshot"
        loading="lazy"
      />
      <div className="pt-2">
        <h2 className="text-xl font-bold text-center truncate underline underline-offset-4">{contact.firstName} {contact.lastName}</h2>
      </div>
    </div>
  );
};

export default ManagerCard;
