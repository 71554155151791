import React from "react";

const MapEmbed = ({ className }) => {
  // Funktio ottaa vastaan className
  // jolla määritellään komponentin tyyli esim. koko
  return (
    <div>
      <iframe
        title="google-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d988.5757325483671!2d25.795771642354897!3d62.27123488242519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46857513af3c3193%3A0xbe679a820a80af75!2sPasram%20Oy!5e0!3m2!1sfi!2sfi!4v1709711694870!5m2!1sfi!2sfi"
        className={className}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default MapEmbed;
