import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainContent from './content/MainContent';
import Contact from './content/Contact';
import IctService from './content/IctService';
import DesignService from './content/DesignService';
import SaleService from './content/SaleService';
import ManufacturingService from './content/ManufacturingService';
import ContactInfo from './content/ContactInfo';
import AllContacts from './content/AllContacts';
import Company from './content/Company';
import IctApplication from './content/IctApplication';
import ElectricalApplication from './content/ElectricalApplication';
import AutomationApplication from './content/AutomationApplication';
import TechnicianApplication from './content/TechnicianApplication';
import PrivacyPolicy from './content/PrivacyPolicy';

const RoutesConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<MainContent />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/ict" element={<IctService />} />
      <Route path="/design" element={<DesignService />} />
      <Route path="/sale" element={<SaleService />} />
      <Route path="/manufacturing" element={<ManufacturingService />} />
      <Route path="/contactinfo" element={<ContactInfo />} />
      <Route path="/all_contacts" element={<AllContacts />} />
      <Route path="/company" element={<Company />} />
      <Route path="/ict_recruit" element={<IctApplication />} />
      <Route path="/electrical_recruit" element={<ElectricalApplication />} />
      <Route path="/automation_recruit" element={<AutomationApplication />} />
      <Route path="/technician_recruit" element={<TechnicianApplication />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default RoutesConfig;
