import React, { useState } from 'react';
import NavButton from './NavButton';
import { baseButtonStyles, mobileNavigationStyles } from '../../styles/navigationStyles';

// Mobile Navissa dropdownvalikon tekevä komponentti
const DropdownMobile = ({ label, items, setIsMobileMenuOpen = () => { } }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className={`${baseButtonStyles} ${mobileNavigationStyles}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-row items-center">
          {label}
          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: '8px', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s ease' }}
          >
            <path
              d="M7 10l5 5 5-5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        
      </div>
      {isOpen && items.map((item, index) => (
        <NavButton key={index} to={item.to} mobile setIsMobileMenuOpen={setIsMobileMenuOpen} className={"pl-10"}>{item.title}</NavButton>
      ))}
    </>
  );
};

export default DropdownMobile;
