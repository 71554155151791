import React from 'react';
import { useTranslation } from "react-i18next";
import Content from '../containers/Content';
import AutomationApplicationFrom from '../forms/AutomationApplicationForm';
import { Helmet } from 'react-helmet';
import Title from '../components/Title';

const AutomationApplication = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('recruit_page.automation.meta_title')}</title> 
        <meta name="description" content={t('recruit_page.automation.meta_description')} />
      </Helmet>
      <Content className="px-8 mx-auto md:w-full">
        <div className="flex flex-col my-auto gap-4">
    
          <div className="flex flex-col md:flex-row">

            {/* Vasen */}
            <div className="flex basis-2/3 flex-col md:px-4 space-y-4 font-medium">
              <Title title={t('recruit_page.automation.header')} className="text-start" />
              <div className="text-base leading-relaxed">
                {t('recruit_page.automation.subtext1')}
              </div>
              <div className="text-base leading-relaxed">
                {t('recruit_page.automation.subtext2')}
              </div>
              <div className="text-base leading-relaxed">
                {t('recruit_page.automation.subtext3')}
              </div>
              <div className="text-sm leading-relaxed italic text-gray-200 pt-4">
                {t('recruit_page.disclaimer')}
              </div>
            </div>
    
            {/* Oikea */}
            <div className="basis-1/3 max-w-full overflow-hidden md:border-l border-white md:px-4 md:mt-0 mt-4 md:pt-0 pt-4 pb-2">
              <AutomationApplicationFrom />
            </div>
          </div>
        </div>
      </Content>
    </>
  );
  
};

export default AutomationApplication;