import React from 'react';
import i18n from '../i18n';

const LanguageSwitcher = () => {
  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="absolute top-0 left-0 p-2.5 flex items-center">
      <button 
        className="font-medium"
        onClick={() => switchLanguage('fi')}>
        FI
      </button>
      <div className="mx-1.5 bg-white h-3 w-px"></div>
      <button 
        className="font-medium"
        onClick={() => switchLanguage('en')}>
        EN
      </button>
    </div>
  );
  
};

export default LanguageSwitcher;
