import React from "react";
import { getImagePath } from "../../utils/getImagePath";
import { useTranslation } from 'react-i18next';

const ContactCard = ({ contact, className }) => {
  const { t } = useTranslation();

  const getLocalizedTitle = (titleKey) => {
    return t(titleKey);
  };

  return (
    <div className={`border border-white sm:w-[225px] lg:w-full ${className} bg-black bg-opacity-25`}  title={`${contact.firstName} ${contact.lastName}\n${contact.phoneNumber}`}>
      <div className="flex flex-row">
        <div className="flex-shrink-0">
          <img
            src={getImagePath(contact.imageFileName)}
            alt="headshot"
            loading="lazy"
            className="w-20 h-20"
          />
        </div>
        <div className="flex flex-col px-2 truncate overflow-hidden">
          <div className="text-lg font-bold underline underline-offset-2 truncate" >
            {contact.firstName} {contact.lastName}
          </div>
          <div className="font-medium truncate">{contact.phoneNumber}</div>
          <div className="truncate">
            {contact.title && contact.title !== "" ? (
              <div className="truncate font-medium">
                {getLocalizedTitle(`company_page.departments.${contact.title}`)}
              </div>
            ) : (
              <div className="invisible">placeholder</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
