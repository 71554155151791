import React from 'react';
import GenericForm from './GenericForm';
import { sendEmail } from '../services/formService';
import { useTranslation } from "react-i18next";

const { contactValidationSchema } = require('shared/formSchemas');

// Backendin assets/email_configs vastaava avain
const endpoint = "contact"

const subjectOptions = [
  { value: 'ict', label: "contact_page.subject_options.ict" },
  { value: 'design', label: "contact_page.subject_options.design" },
  { value: 'sale', label: "contact_page.subject_options.sale" },
  { value: 'request', label: "contact_page.subject_options.request"},
  { value: 'other', label: "contact_page.subject_options.other"}
];

// Fields configuration
const formFields = [
  { name: 'firstName', type: 'text', label: "contact_page.form.firstname" },
  { name: 'lastName', type: 'text', label: "contact_page.form.lastname" },
  { name: 'email', type: 'email', label: "contact_page.form.email" },
  { name: 'subject', type: 'select', label: "contact_page.form.subject", options: subjectOptions},
  { name: 'message', type: 'textarea', label: "contact_page.form.message" },
];

const ContactForm = ({initialSubject}) => {
  const { t } = useTranslation();

  // Initial values
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    subject: initialSubject || 'other',
    message: '', 
    privacyPolicyAccepted: false
  };

  const translatedFields = formFields.map(field => ({
    ...field,
    label: t(field.label),
  }));

  return (
    <GenericForm
      initialValues={initialValues}
      validationSchema={contactValidationSchema}
      onSubmit={(values, actions) => sendEmail(values, endpoint)}
      fields={translatedFields}
    />
  );
};

export default ContactForm;
