import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../components/navigation/NavigationBar';
import MobileMenuButton from '../components/navigation/MobileMenuButton';
import NavigationMobile from '../components/navigation/NavigationMobile';
import Logo from '../components/navigation/Logo';

const Navigation = () => {
  const { t } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Ensisijaiset navigointi objektit
  const mainNavItems = [
    "frontpage", "services", "company", "contactinfo", "recruit", "contact"
  ].map(key => ({
    label: t(`navigation.${key}.label`),
    to: t(`navigation.${key}.path`),
    special: key === "contact",
  }));

  // Palvelut alavalikon objektit
  const serviceItems = Object.keys(t('navigation.services.sublinks', { returnObjects: true }))
    .map(subkey => ({
      title: t(`navigation.services.sublinks.${subkey}.label`),
      to: t(`navigation.services.sublinks.${subkey}.path`)
  }));
  
  // Rekrytointi alavalikon objektit
  const recruitItems = Object.keys(t('navigation.recruit.sublinks', { returnObjects: true }))
    .map(subkey => ({
      title: t(`navigation.recruit.sublinks.${subkey}.label`),
      to: t(`navigation.recruit.sublinks.${subkey}.path`)
  }));

  return (
    <div className="flex justify-between items-center h-32 lg:h-36 max-w-[1520px] mx-auto px-4 sm:px-6 lg:px-8 w-full">
      <div className="flex-shrink-0">
        <Logo />
      </div>
      {/* NavBar, Näkyvissä isoilla ruuduilla */}
      <div className="hidden lg:block">
        <NavigationBar mainNavItems={mainNavItems} serviceItems={serviceItems} recruitItems={recruitItems}/>
      </div>
      {/* Mobile Menun painike */}
      <div className="lg:hidden items-center fixed right-0 z-50 m-4 pr-4">
        <MobileMenuButton setIsMobileMenuOpen={setIsMobileMenuOpen} isMobileMenuOpen={isMobileMenuOpen} />
      </div>
      {/* Mobile Navigaatio, kun isMobileMenuOpen true */}
      {isMobileMenuOpen && (
        <div className="lg:hidden">
          <NavigationMobile setIsMobileMenuOpen={setIsMobileMenuOpen} isMobileMenuOpen={isMobileMenuOpen} mainNavItems={mainNavItems} serviceItems={serviceItems} recruitItems={recruitItems}/>
        </div>
      )}
    </div>
  );
};

export default Navigation;
