import React from "react";
import { useTranslation } from "react-i18next";
import Content from "../containers/Content";
import ServiceLinks from "../containers/ServiceLinks";
import CommonButton from "../components/CommonButton";
import { Helmet } from 'react-helmet';

const MainContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
          <title>{t('main_page.meta_title')}</title> 
          <meta name="description" content={t('main_page.meta_description')} />
      </Helmet>
      <Content className="xl:max-w-[1480px] justify-center mx-auto flex-grow">
        <div className="flex flex-col my-auto md:px-4 xl:-mx-8">
          <div className="font-bold text-4xl -mt-8 sm:text-4xl md:text-5xl md:max-w-3xl lg:ml-4 lg:mt-0 lg:max-w-7xl">
            <div className="flex flex-col md:flex-row animate-slideInLeft">
              <span className="block whitespace-nowrap">{t("main_page.header_line1")}</span>
              <span className="block whitespace-nowrap ml-[1rem]">{t("main_page.header_line2")}</span>
            </div>
            <span className="block ml-[2rem] mt-[0.25rem] animate-slideInLeft">{t("main_page.header_line3")}</span>
          </div>
          <div className="mt-2 max-w-[20rem] text-center sm:ml-[3.5rem] sm:max-w-[24rem] sm:text-left md:ml-[5rem] md:max-w-[32rem]">
            <div className="mx-[1rem] whitespace-break-spaces mt-2 mb-4 font-special font-medium text-lg sm:mx-0 sm:text-xl md:text-2xl animate-slideInLeft">
              {t("main_page.subtext")}
            </div>
            <div className="flex flex-row gap-2 mt-6 lg:mt-0 lg:block">
              <CommonButton to="/company" className="flex-1 px-6 py-3 border-white lg:hover:border-highlight lg:hover:shadow-subtle-glow transition-transform transform hover:scale-105 animate-slideUp" style={{ animationDelay: '0.9s' }}>
                {t("main_page.read_more")}
              </CommonButton>
              <CommonButton to="/contact" special className="block flex-1 border-white lg:hidden px-6 py-3 transition-transform transform hover:scale-105 animate-slideUp">
                {t("main_page.contact")}
              </CommonButton>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex lg:flex-col mx-16 mb-8 animate-slideUp">
          <ServiceLinks />
        </div>
      </Content>
    </>
  );
};

export default MainContent;
