import React from 'react';

// Mobiilimenun avauspainike
const MobileMenuButton = ({ setIsMobileMenuOpen, isMobileMenuOpen }) => {
  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="lg:hidden cursor-pointer" onClick={toggleMenu}>
      {isMobileMenuOpen ? (
        // X ikoni, kun menu auki
        <svg key="closeIcon" className="h-10 w-10 animate-fadeInFast" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      ) : (
        // Menu ikoni, kun menu kiinni
        <svg key="openIcon" className="h-10 w-10 animate-fadeInSlow" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
        </svg>
      )}
    </div>
  );
};


export default MobileMenuButton;
