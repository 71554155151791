import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './assets/locales/en/en.json';
import newsEN from './assets/locales/en/news_en.json';
import privacyPolicyEN from './assets/locales/en/privacy_policy_en.json';

import translationFI from './assets/locales/fi/fi.json';
import newsFI from './assets/locales/fi/news_fi.json';
import privacyPolicyFI from './assets/locales/fi/privacy_policy_fi.json';

const resources = {
  en: {
    translation: translationEN,
    news: newsEN.news,
    privacy_policy: privacyPolicyEN.privacy_policy
  },
  fi: {
    translation: translationFI,
    news: newsFI.news,
    privacy_policy: privacyPolicyFI.privacy_policy
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fi',
    fallbackLng: 'fi',
    debug: false, 
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
