import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useBackgroundImage } from '../contexts/BackgroundContext';
import imageUrls from '../utils/imagePaths';

// Muutettu Content ottamaan vastaan className erilaista asettelua varten riippuen sivusta
const Content = ({ children, className }) => {
  const { pathname } = useLocation();
  const { setBackgroundImage } = useBackgroundImage();

  useEffect(() => {
    switch (pathname) {
      case '/':
        setBackgroundImage(imageUrls.mainBackground);
        break;
      default:
        setBackgroundImage(imageUrls.mainBackground);
    }
  }, [pathname, setBackgroundImage]);

  return (
    // Jos teet muutoksia tähän, tarkista kaikki sivut. 
    // Passaamalla Contentille luokkia voit muuttaa yksittäisen sivun näkymää
    <div className={`flex flex-col flex-grow max-w-[1180px] xl:px-0 text-shadow ${className}`}>      
      {children}
    </div>
  );
};

export default Content;
