import React from "react";
import { useTranslation } from "react-i18next";
import ServiceLink from "../components/ServiceLink";

const ServiceLinks = () => {
  const { t } = useTranslation();

  const serviceItems = Object.keys(t('navigation.services.sublinks', { returnObjects: true }))
    .map(key => ({
      key: key,
      title: t(`navigation.services.sublinks.${key}.label`),
      link: t(`navigation.services.sublinks.${key}.path`)
    }));

  return (
    <>
      <div className="text-center mb-2">
        <h2 className="text-3xl font-bold font-special"> {t(`main_page.services`)} </h2>
        <div className="w-full max-w-xs mx-auto">
          <hr className="border-t border-white" />
        </div>
      </div>
      <div className="flex flex-row gap-4 justify-center w-full">
        {serviceItems.map((item) => (
          <ServiceLink
            key={item.key}
            title={item.title}
            link={item.link}
          />
        ))}
      </div>
    </>
  );

};

export default ServiceLinks;
