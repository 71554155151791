import React from 'react';
import { useTranslation } from "react-i18next";
import Content from '../containers/Content';
import Title from '../components/Title';

const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation();
    const privacy_policy = i18n.getResource(i18n.language, 'privacy_policy');

    return (
        <Content className="px-8 mx-auto md:w-full">
            <div className="flex flex-col my-auto gap-2 font-medium">
                <Title title={t(privacy_policy.title)} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {privacy_policy.sections.map((section, index) => (
                        <div key={index} className="flex flex-col p-1">
                            <Title title={section.section} className="text-base"/>
                            <p className="flex-1">{section.content}</p>
                        </div>
                    ))}
                </div>
            </div>
        </Content>
    );
};

export default PrivacyPolicy;
