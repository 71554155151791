import React from "react";
import { useTranslation } from "react-i18next";

const Title = ({ title, className }) => {
  const { t } = useTranslation();

  return (
    <div className={`${className}`}>
        <div className={`text-2xl font-bold font-special ${className}`}>
          <h2>{t(title)}</h2>
        </div>
    </div>
  );
};

export default Title;
