import React from 'react';
import { useTranslation } from 'react-i18next';
import NavButton from './NavButton';
import Dropdown from './Dropdown';

// Työpöytänavigaatio
const NavigationBar = ({mainNavItems, serviceItems, recruitItems}) => {
  const { t } = useTranslation();

  return (
    <nav className="flex justify-start lg:border-2 lg:border-highlight items-center">
      <div className="flex flex-col lg:flex-row w-full">
        {mainNavItems.map((item, index) => {
          // Jos item.label on "services", tehdään Dropdown valikko serviceItemsillä.
          if (item.label === t('navigation.services.label')) {
            return <Dropdown key={index} title={item.label} items={serviceItems} />;
          }
          // Jos item.label on "recruit", tehdään Dropdown valikko serviceItemsillä.
          if (item.label === t('navigation.recruit.label')) {
            return <Dropdown key={index} title={item.label} items={recruitItems} />;
          } else {
            // Defaulttina NavButton
            return <NavButton key={index} to={item.to} special={item.special}>{item.label}</NavButton>;
          }
        })}
      </div>
    </nav>
  );
};

export default NavigationBar;
