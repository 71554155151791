import React from 'react';
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const CookieConsentBanner = ({ onConsentGiven }) => {
  const { t } = useTranslation();
  const handleAccept = () => {
    localStorage.setItem('ga-consent', 'true');
    onConsentGiven();
  };

  return (
    <CookieConsent
      onAccept={handleAccept}
      location="bottom"
      buttonText={t("general.cookie_agree")}
      declineButtonText={t("general.cookie_decline")}
      cookieName="user-consent"
      style={{
        background: "#fff",
        color: "#000",
        border: "2px solid #c02673",
        opacity: "0.9",
      }}
      buttonStyle={{
        color: "#fff",
        background: "#c02673",
        border: "1px solid #000",
        borderRadius: "1px",
        marginLeft: "6px",
        marginRight: "6px",
        padding: "6px 6px"
      }}
      declineButtonStyle={{
        color: "#fff",
        background: "#606060",
        border: "1px solid #000",
        borderRadius: "1px",
        marginLeft: "6px",
        marginRight: "6px",
        padding: "6px 6px"
      }}
      buttonWrapperClasses='m-2'
      expires={150}
      enableDeclineButton
    >
      <div className="text-sm font-medium">
        {t("general.cookie_banner")}{' '}
        <Link to="/privacy_policy" className="underline">
          {t("general.cookie_read_more")}
        </Link>
      </div>
    </CookieConsent>
  );
};

export default CookieConsentBanner;

