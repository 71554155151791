import React from 'react';
import { useTranslation } from "react-i18next";
import Content from '../containers/Content';
import ManagerCard from '../components/company/ManagerCard';
import Title from '../components/Title';
import NewsFeed from '../components/company/NewsFeed';
import contacts from '../assets/contacts/contacts.json';
import { Helmet } from 'react-helmet';

const Company = () => {
  const { t, i18n } = useTranslation();
  const news = i18n.getResource(i18n.language, 'news');

  return (
    <>
      <Helmet>
        <title>{t('company_page.meta_title', { lng: 'fi' })}</title> 
        <meta name="description" content={t('company_page.meta_description', { lng: 'fi' })} />
      </Helmet>
      <Content className="px-8 mx-auto">
        <div className="flex flex-col my-auto gap-4">

          <Title title={t('company_page.header')} className={"text-center pb-2"}></Title>

          {/* Jaetaan sivu isolla näytöllä kahteen */}
          <div className="flex flex-col lg:flex-row gap-4">

            {/* Vasen puoli */}
            <div className="flex-1 flex flex-col gap-6">
              {/* Kuvat */}
              <div className="flex flex-row justify-center gap-4">
                <ManagerCard contact={contacts[1]}/>
                <ManagerCard contact={contacts[0]}/>
              </div>
              {/* Teksti */}
              <div className="text-base text-center leading-relaxed font-semibold">
                <div>{t('company_page.introduction')}</div>
              </div>
            </div>

            {/* Oikea puoli */}
            <div className="flex-1 flex-col gap-6">
              <div className="flex-1">
                <NewsFeed news={news} className="border-white lg:border-l lg:pl-4" />
              </div>
            </div>

          </div>
        </div>
      </Content>
    </>
  );
};

export default Company;
