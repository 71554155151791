import React from 'react';
import { baseButtonStyles } from '../../styles/navigationStyles';


// Navigaatiopalkissa dropdown valikon avauspainike
const DropdownTrigger = ({ title, setIsOpen }) => {
  return (
    <div className={baseButtonStyles} onMouseEnter={() => setIsOpen(true)}>
      {title}
    </div>
  );
};

export default DropdownTrigger;
