import React, { createContext, useContext, useState } from 'react';
import imageUrls from '../utils/imagePaths';

const BackgroundImageContext = createContext();

export const useBackgroundImage = () => useContext(BackgroundImageContext);

export const BackgroundImageProvider = ({ children }) => {
  const [backgroundImage, setBackgroundImage] = useState(imageUrls.mainBackground);

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: 0.65,
  };

  return (
    <BackgroundImageContext.Provider value={{ backgroundImage, setBackgroundImage }}>
      <div className="fixed inset-0 z-[-1] bg-black overflow-hidden flex justify-center items-center">
        <div style={backgroundStyle} className="absolute inset-0 w-full h-full" />
      </div>
      {children}
    </BackgroundImageContext.Provider>
  );
};
