import React from "react";
import { Link } from "react-router-dom";

const ServiceLink = ({ title, link }) => {
  return (
    <Link
      to={link}
      className="flex flex-1 items-center justify-center text-center font-medium font-special text-lg
                 border border-white px-4 py-4 bg-black bg-opacity-25 shadow-lg
                 hover:border-highlight hover:shadow-subtle-glow cursor-pointer min-h-[100px]
                 transition-transform transform hover:scale-105"
    >
      <div>
        <h2>{title}</h2>
      </div>
    </Link>
  );
};

export default ServiceLink;
