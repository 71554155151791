import React from 'react';
import imageUrls from '../../utils/imagePaths';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to={'/'}>
      <div className="m-4">
        <img src={imageUrls.logo} alt="Company Logo" className="max-w-[160px] lg:max-w-[200px] xl:max-w-[280px]" />
      </div>
    </Link>
  );
};

export default Logo;
