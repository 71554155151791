import React from 'react';
import FooterContent from '../content/FooterContent.js'; 

const Footer = () => {
  return (
    <footer className="flex justify-between items-center mt-6 h-6 px-10 bg-black bg-opacity-50">
      <FooterContent />
    </footer>
  );
};

export default Footer;
