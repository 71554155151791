import React from "react";
import { useTranslation } from "react-i18next";

const GeneralContact = ({ contactKey, className }) => {
  const { t } = useTranslation();

  const contactData = t(`contactinfo_page.${contactKey}`, {
    returnObjects: true,
  });

  return (
    <div key={contactKey} className={`flex flex-col ${className}`}>
      {/* Otsikko special fontilla, muu teksti normaalina sisennyksellä*/}
      {Object.entries(contactData).map(([dataKey, value]) => (
        <p
          key={dataKey}
          className={dataKey === "title" ? "text-lg font-special font-bold" : "font-medium ml-4"}
        >
          {value}
        </p>
      ))}
    </div>
  );
};

export default GeneralContact;
