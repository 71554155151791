import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMobile from './DropdownMobile';
import NavButton from './NavButton';

// Mobiilinavigaatio
const NavigationMobile = ({ setIsMobileMenuOpen, isMobileMenuOpen, mainNavItems, serviceItems, recruitItems }) => {
  const { t } = useTranslation();
  if (!isMobileMenuOpen) return null;

  return (
    <div className="fixed top-0 right-0 z-40 pt-32 flex flex-col w-full h-full bg-black bg-opacity-75 overflow-y-auto backdrop-blur-[2px]">
      {mainNavItems.map((item, index) => {
        if (item.label === t('navigation.services.label')) {  
          return <DropdownMobile key={index} label={item.label} items={serviceItems} setIsMobileMenuOpen={setIsMobileMenuOpen} />;
        }
        if (item.label === t('navigation.recruit.label')) {  
          return <DropdownMobile key={index} label={item.label} items={recruitItems} setIsMobileMenuOpen={setIsMobileMenuOpen} />;
        } else {
          return <NavButton key={index} to={item.to} special={item.special} mobile setIsMobileMenuOpen={setIsMobileMenuOpen}>{item.label}</NavButton>;
        }
      })}
    </div>
  );
};

export default NavigationMobile;
