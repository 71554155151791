import React from 'react';
import GenericForm from './GenericForm';
import { sendEmail } from '../services/formService';
import { useTranslation } from "react-i18next";
import { handleFileInputChange } from '../utils/fileHandler';

const { automationApplicationValidationSchema } = require('shared/formSchemas');

// Backendin assets/email_configs vastaava avain
const endpoint = "automation_application"

// Tila mahdollisille esitäytetyille tiedoille
const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  degree: '',
  message: '',
  fileCv: '',
  fileOptional: '', 
  privacyPolicyAccepted: false
};

// Lomakkeen kentät
const formFields = [
  { name: 'firstName', type: 'text', label: "general.application_form.first_name" },
  { name: 'lastName', type: 'text', label: "general.application_form.last_name" },
  { name: 'phone', type: 'tel', label: "general.application_form.phone" },
  { name: 'email', type: 'email', label: "general.application_form.email" },
  { name: 'degree', type: 'text', label: "general.application_form.degree" },
  { name: 'message', type: 'textarea', label: "general.application_form.application_message" },
  {
    name: 'fileCv',
    type: 'file',
    label: "general.application_form.file_cv",
    onChange: (event, setFieldValue) => handleFileInputChange(event, setFieldValue, "fileCv"),
  },
  {
    name: 'fileOptional',
    type: 'file',
    label: "general.application_form.file_optional",
    onChange: (event, setFieldValue) => handleFileInputChange(event, setFieldValue, "fileOptional"),
  },
];


const AutomationApplicationForm = () => {
  const { t } = useTranslation();

  const translatedFields = formFields.map(field => ({
    ...field,
    label: t(field.label),
  }));

  return (
    <GenericForm
      initialValues={initialValues}
      validationSchema={automationApplicationValidationSchema}
      onSubmit={(values, actions) => sendEmail(values, endpoint)}
      fields={translatedFields}
    />
  );
};

export default AutomationApplicationForm;