// Funktio, jolla käsitellään formistä löytyvä file tyyppinen kenttä
const handleFileInputChange = (event, setFieldValue, fieldName) => {
  const file = event.currentTarget.files[0];
  if (file) {
    if (file.size <= 5242880 && file.type === "application/pdf") {
      setFieldValue(fieldName, file);
      return file;
    } else {
      // Palaute pieleen menneestä tai vääränlaisesta tiedostosta
      const errorMessage = file.size > 5242880
        ? 'File is too large. Max size is 5MB'
        : 'Invalid file type. Only PDF files are allowed.';
      alert(errorMessage);
      return null;
    }
  } else {
    return null;
  }
  
};

export { handleFileInputChange }