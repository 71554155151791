import React, {useState, useEffect} from 'react';
import NewsPost from './NewsPost';
import usePagination from '../../hooks/usePagination';
import useWindowSize from '../../hooks/useWindowSize';

const NewsFeed = ({ news, className }) => {
  const width = useWindowSize();
  const [postsPerPage, setPostsPerPage] = useState(getPostsPerPage(width));
  const { currentItemsSlice, ArrowLeft, ArrowRight, PageNumbers, setCurrentPage } = usePagination(news.length, postsPerPage);
  const [expandedPostId, setExpandedPostId] = useState(null);

  // Järjestellään uutiset pvm mukaan ja leikataan siitä paloja
  const sortedNews = news.sort((a, b) => new Date(b.date) - new Date(a.date));
  const currentNews = sortedNews.slice(currentItemsSlice.start, currentItemsSlice.end);

  // Postausten määrän muuttaminen sivun koon mukaan
  useEffect(() => {
    const newContactsPerPage = getPostsPerPage(width);
    setPostsPerPage(newContactsPerPage);
  }, [width]);

  function getPostsPerPage(width) {
    if (width > 1024) return 4;
    return 2;
  }

  // Sivun vaihto jos meinataan näyttää sivu jotai ei ole
  useEffect(() => {
    const newPostsPerPage = getPostsPerPage(width);
    if (postsPerPage !== newPostsPerPage) {
      setPostsPerPage(newPostsPerPage);
  
      const totalPages = Math.ceil(news.length / newPostsPerPage);

      const currentPage = Math.min(usePagination.current, totalPages);
      setCurrentPage(currentPage > 0 ? currentPage : 1);
    }
  }, [width, postsPerPage, news.length, setCurrentPage]);  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Laajennus toggle
  const toggleExpandPost = (postId) => {
    if (expandedPostId === postId) { 
      setExpandedPostId(null);
    } else {
      setExpandedPostId(postId);
    }
  };

  return (
    <div>
      <div className={`flex flex-col gap-2 ${className}`}>
        <div className={`grid ${expandedPostId ? 'grid-cols-1' : 'grid-cols-1 sm:grid-rows-2 lg:grid-rows-4'} gap-2`}>
          {currentNews.map((post, index) => {
            // Jos toggle, näytetään vain laajennettu postaus
            if (!expandedPostId || expandedPostId === post.id) {
              return (
                <NewsPost
                  key={index}
                  post={post}
                  isExpanded={expandedPostId === post.id}
                  onToggleExpand={() => toggleExpandPost(post.id)}
                  postsPerPage={postsPerPage} // Passataan alueen koon laskentaa varten
                />

              );
            }
            return null;
          })}
        </div>
      </div>
  
      {/* Paginaatio, piilotetaan jos postaus laajennettu */}
      <div className={`${expandedPostId === null ? '' : 'opacity-0 pointer-events-none'} flex justify-center items-center mt-4`}>
        <ArrowLeft />
        <PageNumbers onPageChange={handlePageChange} />
        <ArrowRight />
      </div>

    </div>
  );
  
};

export default NewsFeed;
