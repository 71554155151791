import { useState, useEffect } from 'react';

// Palauttaa ikkunan koon realiajassa
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //console.log(windowSize)
  return windowSize;
}

export default useWindowSize;