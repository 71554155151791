import React, { useState } from 'react';
import DropdownTrigger from './DropdownTrigger';
import DropdownItem from './DropdownItem';

// Triggeristä ja Itemeistä rakennettu Dropdown -kokonaisuus
const Dropdown = ({ title, items }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      className="inline-block relative"
      onMouseLeave={() => setDropdownOpen(false)}
    >
      <DropdownTrigger title={title} setIsOpen={setDropdownOpen} />
        <div className={`absolute left-0 origin-top transform transition-transform duration-300 ease-out flex flex-col z-10 bg-black bg-opacity-25 border-2 border-highlight shadow-lg
                      ${dropdownOpen ? "opacity-100 scale-100 visible" : "opacity-0 scale-95 invisible"}`}
        >
        {items.map((item, index) => (
          <DropdownItem key={index} to={item.to}>{item.title}</DropdownItem>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
