import React from 'react';
import { Link } from 'react-router-dom';

// Navigaatiopalkissa dropdown valikon linkki
const DropdownItem = ({ to, children }) => {
  const itemStyles = "whitespace-nowrap px-4 py-2 bg-[#737373] bg-opacity-25 border-t first:border-t-0 border-highlight hover:bg-highlight backdrop-blur-sm hover:bg-opacity-25 max-w-[500px]";

  return (
    <Link to={to} className={itemStyles}>
      {children}
    </Link>
  );
};

export default DropdownItem;
