import React from 'react';

const ServiceCard = ({ service_card }) => {
  return (
    <div className={`flex flex-col border border-white bg-black bg-opacity-25 h-full text-left px-3 py-1.5`}>
      <div className="flex flex-col">
          <h3 className="text-left text-lg font-special font-bold">{service_card.title}</h3>
          <div className="w-full max-w-[65%] lg:max-w-[75%]">
            <hr className="border-t border-white" />
          </div>
          <p className="font-medium pt-2">{service_card.subtext}</p>
      </div>
    </div>
  );
};

export default ServiceCard;

