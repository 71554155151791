import React from 'react';
import { Link } from 'react-router-dom';

const CommonButton = ({ children, special, to, className, onClick, disabled, type }) => {
  
  const baseStyles = "inline-block cursor-pointer border text-lg text-center w-fit shadow-lg";
  const defaultStyles = " bg-[#737373] bg-opacity-25";
  const specialStyles = " bg-highlight bg-opacity-25 min-w-[100px]";
  const disabledStyles = "opacity-50";

  const buttonClass = `${baseStyles} ${special ? specialStyles : defaultStyles} ${disabled ? disabledStyles : ''} ${className}`;

  if (onClick) {
    return (
      <button onClick={onClick} className={buttonClass} disabled={disabled}>
        {children}
      </button>
    );
  }

  if (type === "submit") {
    return (
      <button type={type} onClick={onClick} className={buttonClass} disabled={disabled}>
        {children}
      </button>
    );
  }

  return (
    <Link to={to} className={buttonClass}>
      {children}
    </Link>
  );
};

export default CommonButton;
